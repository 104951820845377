import React, { useEffect, useState } from "react";
import styles from "../styles.module.css";
import { Rate, Button, Radio, message } from "antd";
import CheckOutlined from "@ant-design/icons";
import Request from "../../../request";
import { log } from "async";
import moment from "moment";
import async from 'async'
import { confirmBox } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, notification, Tag } from "antd";
import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { C } from "jssip";

export default function Component() {
  const { currentUser, todaysCallbacks, associatedcustomer } = useSelector(
    (state) => ({
      currentUser: state.global.currentUser,
      todaysCallbacks: state.global.todaysCallbacks,
      associatedcustomer: state.global.associatedcustomer,
    })
  );
  const [plansData, setPlansData] = useState([]);

  const [loading, setLoader] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [planDuration, setPlanDuration] = useState(1);
  const [dataPlan, setdataPlan] = useState();
  const dispatch = useDispatch();

  const fetchPlans = () => {
    Request.getPlans()
      .then((res) => {
        console.log(res.data);
        const filteredPlans = res.data.filter((plan) => plan.planid !== 1);
        const filteredPlansActive = filteredPlans.filter((plan) => plan.planactive == true);
        setPlansData(filteredPlansActive);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const buyPlan = async (val) => {
    if ((moment(currentUser?.sessionsDetail?.end_date).diff(moment(), 'days') > 0) && val?.planid < currentUser?.planDetails?.planid) {
      notification.error({
        message: "You can't downgrade before current plan expiry"
      });
      return
    }
    if (((currentUser?.sessionsDetail?.allowed_sessions == currentUser?.sessionsDetail?.sessions_counter) || (moment(currentUser?.sessionsDetail?.end_date).diff(moment(), 'days') >= 14)) && val?.planid <= currentUser?.planDetails?.planid) {
      notification.error({
        message: "Can't perform renew before 14 days of Plan Expiry"
      })
      return;
    }
    var monthAmount, updatedAllowedConnections;
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var remainingDays =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    const monthDays = moment().daysInMonth();
    monthAmount = (((val.amount / monthDays) * (remainingDays + 1) * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1)).toFixed(2))
    if (monthAmount < 1) {
      monthAmount = (1.0);
    }
    updatedAllowedConnections = ((val.planallowedconnection * currentUser?.subscriptions))
    const onSelectPlan = (e) => {
      if (e.target.value == "1") {
        monthAmount = (((val?.amount / monthDays) * (remainingDays + 1) * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1)).toFixed(2))
        updatedAllowedConnections = (val?.planallowedconnection * currentUser?.subscriptions)
        setPlanDuration(1)
      } else if (e.target.value == "2") {
        monthAmount = ((((val?.amount / monthDays) * (remainingDays + 1) * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) + (val?.amount * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) * 2)) * 0.9).toFixed(2))
        updatedAllowedConnections = (val?.planallowedconnection * currentUser?.subscriptions * 3)
        setPlanDuration(3)
      } else if (e.target.value == "3") {
        monthAmount = ((((val?.amount / monthDays) * (remainingDays + 1) * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) + (val?.amount * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) * 11)) * 0.8).toFixed(2))
        updatedAllowedConnections = (val?.planallowedconnection * currentUser?.subscriptions * 12)
        setPlanDuration(12)
      }
    }

    const value = {
      order_amount: monthAmount,
      planName: val.planname,
      customer_id: associatedcustomer,
      customer_name: currentUser?.billingname,
      customer_email: currentUser?.emailid,
      customer_phone: currentUser?.contactnumber,
      planId: val?.planid,
      planduration: planDuration,
      updatedAllowedConnections: updatedAllowedConnections,
    };
    confirmBox(dispatch, {
      message: <>
        <Radio.Group className={`${styles.radioPlan}`} onChange={onSelectPlan} defaultValue="1">
          <Radio.Button value="1"><p><b>Monthly:</b> You will be charged {((val?.amount / monthDays) * (remainingDays + 1) * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1)).toFixed(2)} this month for {currentUser?.subscriptions} subscribers(Added during registration)</p><p>
            Max. Allowed Connections: {val?.planallowedconnection * currentUser?.subscriptions}</p></Radio.Button>
          <Radio.Button value="2"><p><b>Quaterly:</b> You will be charged {(((val?.amount / monthDays) * (remainingDays + 1) * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) + (val?.amount * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) * 2)) * 0.9).toFixed(2)} for 3 months for {currentUser?.subscriptions} subscribers(Added during registration)
          </p><p>
              Max. Allowed Connections: {val?.planallowedconnection * currentUser?.subscriptions * 3}</p></Radio.Button>
          <Radio.Button value="3"><p><b>Yearly:</b> You will be charged {(((val?.amount / monthDays) * (remainingDays + 1) * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) + (val?.amount * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) * 11)) * 0.8).toFixed(2)} for 12 months for {currentUser?.subscriptions} subscribers(Added during registration)
          </p><p>Max. Allowed Connections: {val?.planallowedconnection * currentUser?.subscriptions * 12}</p></Radio.Button>
        </Radio.Group></>,
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } = await Request.buyplan(
          value
        );
        setLoader(false);
        if (success) {
          var newData = []
          async.forEach(
            data,
            (val, cb) => {
              if (val?.rel == 'approve') {
                window.location.href = val?.href;
                cb();
              } else {
                cb();
              }
            },
          );
          notification.success({
            message: message || "Success",
          });
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const paymentPage = () => {
    if (sessionId?.toString().length > 1) {
      console.log("I rannn");
      let cashfree = new cashfreeSandbox.Cashfree(sessionId);
      cashfree.redirect();
    }
  };

  useEffect(() => {
    paymentPage();
  }, [sessionId]);
  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <>
      <div className={`${styles.containerAvailablePlans}`}>
        <div className={`${styles.divider}`}>Other Available Plans</div>
      </div>
      <div className={`${styles.cardsContainer}`}>
        {plansData.map((plan) => (
          <div className={styles.plancard} key={plan.planid}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>{plan.planname}</h2>
              <div className={styles.price}>$ {plan.amount}</div>
            </div>
            <div className={styles.layer2}>
              <div
                className="plan-features"
                style={{ display: "flex", flexDirection: "column", gap: "1em" }}
              >
                <h4>Plan includes:</h4>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    justifyContent: "flex-start",
                    padding: "0 0",
                  }}
                >
                  <li>
                    {plan.planvalidity} {plan.planterm} Plan Validity
                  </li>
                  <li>{plan.planallowedconnection} Plan Allowed Connections</li>
                  {/* You can add more plan details here */}
                </ul>
              </div>
              <button
                className="buy-now-button"
                onClick={() => {
                  buyPlan(plan);
                }}
              >
                Buy
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
