import axios from "axios";
import { apiUrl, ExcelDownloadFormat } from "./settings";
import {
  fullBrowserVersion,
  browserName,
  osVersion,
  osName,
} from "react-device-detect";
import { saveAs } from "file-saver";
import moment from "moment";
let authAxios = axios.create({
  baseURL: apiUrl,
});
// let authAxios1 = axios.create({
//   baseURL: "http://192.168.12.18:800",
// });

let authAxios1 = axios.create({
  baseURL: apiUrl,
});

authAxios1.interceptors.request.use(
  function (request) {
    // Do something before request is sent
    request.responseType = "blob";
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getToken = () => {
  return {
    headers: {
      Authorization: localStorage.getItem("token"),
      type: "WEB",
      fullbrowserversion: fullBrowserVersion,
      browsername: browserName,
      osversion: osVersion,
      osname: osName,
    },
  };
};

// export const getToken3 = () => {
//     return {
//         headers: {
//             Authorization: 'Token ' + localStorage.getItem('f123c0a191e4b1aea3f999e2e18869497b8182c9'),
//             type: "WEB",
//             fullbrowserversion: fullBrowserVersion,
//             browsername: browserName,
//             osversion: osVersion,
//             osname: osName
//         }
//     }
// }

export const getTokenFromParams = (token) => {
  return {
    headers: {
      Authorization: "token " + token,
      type: "WEB",
      fullbrowserversion: fullBrowserVersion,
      browsername: browserName,
      osversion: osVersion,
      osname: osName,
    },
  };
};
export const getTokenFromParamsForFile = (token) => {
  return {
    headers: {
      Authorization: "token " + token,
      "content-type":
        "multipart/form-data; boundary=ebf9f03029db4c2799ae16b5428b06bd",
      type: "WEB",
      fullbrowserversion: fullBrowserVersion,
      browsername: browserName,
      osversion: osVersion,
      osname: osName,
    },
  };
};
class Request {
  error = (err) => {
    try {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.href = "";
      }
    } catch (e) {}
  };

  // ------------------------------------------- API Start from here --------------------------------------------- //
  signup(data, recaptchaToken) {
    return new Promise((next, error) => {
      authAxios
        .post("/register", data)
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  login(data, recaptchaToken) {
    return new Promise((next, error) => {
      authAxios
        .post("/login", data)
        .then((d) => {
          next(d.data);
          console.log(d);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  captcha(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/login/captcha", data)
        .then((d) => {
          next(d.data);
          console.log(d);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  logout(data, recaptchaToken) {
    return new Promise((next, error) => {
      authAxios
        .post("/logout", data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  // user api

  getProfile() {
    return new Promise((next, error) => {
      authAxios
        .post("/details", {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  sendOTP(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/verifyemail/send-otp", { ...data })
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next(err.response.data);
          this.error(err);
        });
    });
  }
  verifyOTP(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/verifyemail/verify-otp", { ...data })
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next(err.response.data);
          this.error(err);
        });
    });
  }
  fPassword(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/fPassword/resetpassword", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next(err.response.data);
          this.error(err);
        });
    });
  }
  updatePassword(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/fPassword/updatepassword", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next(err.response.data);
          this.error(err);
        });
    });
  }
  getPlans(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/plans", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  buyplan(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/buyplan", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  addSubscritions(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/buysubscriptions", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }

  checkStatus(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/buyplan/check", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }

  dashboard(data) {
    return new Promise((next, error) => {
      //apoi ni h
      authAxios
        .post("/dashboard", data, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  getUser(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/user/list", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  addUser(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/user/adduser", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  updateUser(id, data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/user/updateuser/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({
            error: err.response.data.error,
            message: err.response.data.message,
          });
          this.error(err);
        });
    });
  }
  deleteUser(id) {
    return new Promise((next, error) => {
      authAxios
        .post(`/user/delete/${id}`, {}, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  getCountries(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/countries", { ...data })
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
  getOnline(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/onlinestatus/list", { ...data })
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  getSessions(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/details/sessions", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  getongoing(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/details/ongoingsessions", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  getinvoices(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/invoice/listing", { ...data }, getToken())
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  downloadinvoices(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/invoice/download", data, getToken())
        .then((d) => {
          const linkSource =
            "data:" + `application/pdf` + ";base64, " + d?.data?.data;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = `Invoice${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}.pdf`;
          downloadLink.click();
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  sendcontactsupport(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/contactsupport", { ...data })
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  getsessionhistory(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/sessions/list", { ...data })
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  sendfeedback(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/feedback", { ...data })
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
}
export default new Request();
