import React, { useEffect, useState } from "react";
import plan from "./plan.module.css";
import { notification } from "antd";
import async from 'async'
import { confirmBox } from "../../utils";
import Request from "../../request";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

export default function Component() {
  const dispatch = useDispatch();
  const { currentUser, associatedcustomer } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    associatedcustomer: state.global.associatedcustomer,
  }));

  useEffect(() => {
    funcApi();
  }, []);
  const [loading, setLoader] = useState(false)
  const [userDetails, setUserDetails] = useState({});
  const [monthAmount, setmonthAmount] = useState(0);

  const funcApi = async () => {
    const { data, success, error, message } = await Request.getProfile();
    if (success && data) {
      return setUserDetails(data);
    }
    notification.error({
      message: message || "Some Error Occurred!",
    });
    return setUserDetails({});
  };

  useEffect(() => {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var remainingDays = time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    const monthDays = moment().daysInMonth()
    const discountFactor = currentUser?.sessionsDetail.planduration == 1 ? 1 : currentUser?.sessionsDetail.planduration == 3 ? 0.9 : 0.8
    setmonthAmount((((currentUser?.planDetails.amount / monthDays) * (remainingDays + 1) * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) + (currentUser?.planDetails?.amount * (currentUser?.subscriptions > 1 ? currentUser?.subscriptions : 1) * (currentUser?.sessionsDetail.planduration - 1))) * discountFactor).toFixed(2))
  }, [userDetails])

  const renewPlan = async () => {
    if (((currentUser?.sessionsDetail?.allowed_sessions == currentUser?.sessionsDetail?.sessions_counter) || (moment(currentUser?.sessionsDetail?.end_date).diff(moment(), 'days') >= 14))) {
      notification.error({
        message: "Can't perform renew before 14 days of Plan Expiry"
      })
      return;
    }
    if (monthAmount < 1) {
      setmonthAmount(1.00)
    }
    const value = {
      order_amount: monthAmount,
      planName: currentUser?.planDetails.planname,
      customer_id: associatedcustomer,
      customer_name: currentUser?.billingname,
      customer_email: currentUser?.emailid,
      customer_phone: currentUser?.contactnumber,
      planId: currentUser?.planDetails?.planid,
      planduration: currentUser?.sessionsDetail.planduration,
      updatedAllowedConnections: currentUser?.sessionsDetail?.allowed_sessions
    }
    confirmBox(dispatch, {
      message: <><p>You will be charged - {monthAmount} for {currentUser?.sessionsDetail.planduration} month for {currentUser?.subscriptions} subscribers(Added during registration)</p>
        <p>Max. Allowed Connections: {currentUser?.sessionsDetail?.allowed_sessions}</p></>,
      onOk: async () => {
        setLoader(true)
        let { user, token, success, message, data } = await Request.buyplan(value)
        setLoader(false)
        if (success) {
          var newData = []
          async.forEach(
            data,
            (val, cb) => {
              if (val?.rel == 'approve') {
                console.log(val, "HELLO")
                window.location.href = val?.href;
                cb();
              } else {
                cb();
              }
            },
          );
          setmonthAmount(0)
          notification.success({
            message: message || "Success"
          })
        } else {
          notification.error({
            message: message || "Failed"
          })
        }
      }
    })
  }


  return (
    <div className={`${plan.container}`}>
      <div className={`${plan.currentPlanSec}`}>
        <h2>Current Plan</h2>
        <ul>
          <li>
            <p>Activation Date</p>
            <p>
              {moment(userDetails?.activationdate).format("DD-MM-YYYY") || "NA"}
            </p>
          </li>
          <li>
            <p>Max Allowed Sessions</p>
            <p>{userDetails?.sessionsDetail?.allowed_sessions || "NA"}</p>
          </li>
          <li>
            <p>Max Users Allowed</p>
            <p>{userDetails?.subscriptions || "NA"}</p>
          </li>
          <li>
            <p>Valid Till - </p>
            <p>
              {moment(userDetails?.planexpiry).format("DD-MM-YYYY") || "NA"}
            </p>
          </li>
        </ul>
      </div>
      <div className={`${plan.yourPlansec}`}>
        <p>Your Plan</p>
        <h2 style={{ textTransform: "capitalize" }}>
          {userDetails?.planDetails?.planname || "NA"} Plan
        </h2>
        <p>Renews on {moment(userDetails?.planexpiry).format("DD-MM-YYYY") || "NA"}</p>
        <button
          onClick={() => {
            notification.error({
              message: "Not Working Yet!",
            });
          }}
        >
          Cancel subscription
        </button>
        {currentUser?.planDetails?.planid != 1 &&
          <button
            onClick={renewPlan}
          >
            Renew Plan
          </button>}
      </div>
    </div>
  );
}
