import React, { useState, useEffect } from 'react'
import './styles.less'
import {
    Form,
    Select,
    notification,
    Button,
    Input,
    Upload,
} from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import Request from '../../request';
import { push } from 'connected-react-router';
import _ from 'lodash'
import { useParams } from 'react-router';
const { Option } = Select;

const Login = () => {
    const dispatch = useDispatch()
    const { token } = useParams()
    const [form] = Form.useForm()
    const [loader, setLoader] = useState(false)
 
    const [formData, setFormData] = useState({})
   


    const onFinish = async (valData) => {
        setLoader(true)
        let { user, success, message, data } = await Request.updatePassword({ token, password: valData.password })
        if (success) {
            notification.success({
                message: message || "success",
            })
            setTimeout(() => {

                window.location.href = '/'
            }, 3000)
        }
        else {
            notification.error({
                message: message || "Failed",
                description: JSON.stringify(data).replace('[', '').replace('{', '').replace(']', '').replace('}', '')

            })
        }
        setLoader(false)
    }

    function onChange(key, value) {
        setFormData((prev) => ({ ...prev, [key]: value }))
    }
    useEffect(() => {
    }, [])

    return (
        <div className="registration">
            <div className="dispayContent">
                <div className="">
                    <div className="subregistration">
                        Please Enter New Password !
                    </div>
                </div>
                <div className="drag">
                    <div>
                        <Form name="validate_other" onFinish={onFinish} >
                            <div className="marginLargetop"></div>
                            <div className='inputWidthNew  '>
                                <Form.Item
                                    name="password"
                                    label="New Password"
                                    rules={[
                                        { min: 8,max:16, message: 'Password should be between 8-16 characters' },
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    name="confirm_new_password"
                                    label="Confirm Password"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        { min: 8,max:16, message: 'Password should be between 8-16 characters' },
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                            </div>
                            <div style={{ justifyContent: "center", display: "flex", margin: "1rem" }} >
                                <Button loading={loader} htmlType="submit" color="#FF6600" style={{ width: "200px", borderRadius: '4px', backgroundColor: "#ff6600", color: "#fff" }}>Submit</Button>
                            </div>

                        </Form>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Login