import React from "react";
import { Card, Button, notification } from "antd";
import "./invoice.css";
import { DownloadOutlined } from "@ant-design/icons";
import Request from "../../request";
import moment from "moment";

const invoiceCard = ({ invoiceData }) => {
  const { invoicedate, type, amount, invoiceid, id } = invoiceData;

  const downloadFunc = async () => {
    let InvoiceId;
    switch (type) {
      case "subscription":
        InvoiceId = { id };
        break;

      default:
        InvoiceId = { invoiceid };
        break;
    }

    const { data, success, error, message } = await Request.downloadinvoices(
      InvoiceId
    );
    if (success) {
      notification.success({
        message: "Success!",
      });
    } else {
      notification.error({
        message: "Failed!",
      });
    }
  };

  return (
    <Card style={{ width: "360px", borderRadius: "5px" }}>
      <div className="card-row">
        <p className="headfont">Invoice</p>
        <p className="headfont">{moment(invoicedate).format("MMMM YYYY")}</p>
      </div>
      <div className="card-row">
        <p className="defaultfont">Date</p>
        <p className="defaultfont">
          {moment(invoicedate).format("DD-MM-YYYY")}
        </p>
      </div>
      <div className="card-row">
        <p className="defaultfont">Invoice Type</p>
        <p className="defaultfont">{type}</p>
      </div>
      <div className="card-row">
        <p className="defaultfont">Amount</p>
        <p className="defaultfont">${amount}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={downloadFunc}
          type="primary"
          style={{
            backgroundColor: "white",
            width: "66px",
            height: "35px",
            borderRadius: "8px",
            borderColor: "#2A76AC",
            cursor: "pointer",
          }}
        >
          <DownloadOutlined style={{ color: "#2A76AC" }} />
        </Button>
      </div>
    </Card>
  );
};

export default invoiceCard;
