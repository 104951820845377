// export const apiUrl = 'https://test.collaberus.com:8007'
// export const apiUrl = 'http://localhost:8010'
export const apiUrl = 'https://client.amotus.online/api'

export const DateFormat = 'DD-MM-YYYY'
export const Test = 'collaberus'
export const ExcelFormat = '.csv,.xls,.xlsx'
export const ExcelDownloadFormat = 'xls'
export const AudioDownloadFormat = 'wav'
export const AudioUploadFormat = '.mp3,.wav,.sln,.gsm'
export const ImageFileFormat = '.png,.jpeg,.pdf,.jpg'
export const MaxExcelSize = 5
export const MaxAudioSize = 5
export const MaxFileSize = 2
