import Request from "../../request";
import React, { useState } from "react";
import { Card, Rate, Button, notification } from "antd";
import { useSelector } from "react-redux";
import "./feed.css";

const RatingCard = ({ onSubmit }) => {
  const [ratings, setRatings] = useState({
    easeOfUse: 0,
    features: 0,
    functionality: 0,
    fastAndResponsive: 0,
    reliable: 0,
    overallExperience: 0,
  });
  const [comment, setComment] = useState("");

  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser,
  }));

  const handleRatingChange = (item, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [item]: value,
    }));
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleRatingSubmit = async () => {
    const feedback = {
      easeOfUse: ratings.easeOfUse,
      features: ratings.features,
      functionality: ratings.functionality,
      responsive: ratings.fastAndResponsive,
      reliable: ratings.reliable,
      overall: ratings.overallExperience,
      comment: comment,
      email: currentUser?.emailid,
      customerid: currentUser?.customerid,
    };

    const { msg, success } = await Request.sendfeedback(feedback);

    if (success) {
      setRatings({
        easeOfUse: 0,
        features: 0,
        functionality: 0,
        fastAndResponsive: 0,
        reliable: 0,
        overallExperience: 0,
      });
      setComment("");
      notification.success({ message: msg || "Feedback Submitted" });
      return;
    }

    notification.error({ message: msg || "Some Error occured" });
    return;
  };

  return (
    <div className="last" style={{ padding: "40px" }}>
      <Card style={{ width: 560, borderRadius: "10px" }}>
        <div>
          <div className="heading">Please rate us in the following</div>
          <br></br>
          <div className="starsalign">
            <p>Ease of Use</p>
            <Rate
              allowHalf
              onChange={(value) => handleRatingChange("easeOfUse", value)}
              value={ratings.easeOfUse}
            />
          </div>
          <div className="starsalign">
            <p>Features</p>
            <Rate
              allowHalf
              onChange={(value) => handleRatingChange("features", value)}
              value={ratings.features}
            />
          </div>

          <div className="starsalign">
            <p>Functionality</p>
            <Rate
              allowHalf
              onChange={(value) => handleRatingChange("functionality", value)}
              value={ratings.functionality}
            />
          </div>

          <div className="starsalign">
            <p>Fast & Responsive</p>
            <Rate
              allowHalf
              onChange={(value) =>
                handleRatingChange("fastAndResponsive", value)
              }
              value={ratings.fastAndResponsive}
            />
          </div>

          <div className="starsalign">
            <p>Reliable</p>
            <Rate
              allowHalf
              onChange={(value) => handleRatingChange("reliable", value)}
              value={ratings.reliable}
            />
          </div>

          <div className="starsalign">
            <p>Overall Experience</p>
            <Rate
              allowHalf
              onChange={(value) =>
                handleRatingChange("overallExperience", value)
              }
              value={ratings.overallExperience}
            />
          </div>
        </div>
      </Card>
      <div className="heading">Additional Comments:</div>
      <textarea value={comment} onChange={handleCommentChange} />
      <Button
        onClick={handleRatingSubmit}
        type="primary"
        style={{ borderRadius: "50px" }}
      >
        Submit Ratings
      </Button>
    </div>
  );
};

function Appp() {
  return (
    // <div className="last"style={{ padding:'40px' }}>
    <RatingCard />
    // <div className='heading'>Additional Comments:</div>
    // <textarea  />
    // <Button type="primary"  style={{borderRadius:'50px'}}> Submit Ratings
    //   </Button>
    // </div>
  );
}

export default Appp;
