import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification, Card, Switch } from "antd";
import FormItem from "../../components/FormItem";
import "./styles.less";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import Request from "../../request";
import _ from "lodash";
import { getPushPathWrapper, getUrlParams } from "../../routes";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 8,
  },
};

const Demo = (props) => {
  const { currentUser, associatedcustomer } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    associatedcustomer: state.global.associatedcustomer,
  }));
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [check, setCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [params, setParams] = useState(false);

  const onFinish = async (valData) => {
    valData = {
      associatedcustomer: associatedcustomer,
      ...valData,
    };
    setLoader(true);
    let { user, token, success, message, data } = await Request.addUser({
      ...valData,
    });
    setLoader(false);
    if (success) {
      dispatch(getPushPathWrapper("users"));
      notification.success({
        message: message,
      });
      form.resetFields();
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
  };

  const requiredRule = [{ required: true, message: "Please input field" }];
  const passRule = [
    { min: 8, max: 16, message: "Password should be between 8-16 characters" },
    { required: true, message: "Please input field" },
  ];
  const onChange = (checked) => {
    setCheck(!check);
  };

  return (
    <div className="container">
      <Card className="cardContainer" style={{ background: "#F2F6F8" }}>
        {/* <Form layout='vertical' form={form} onFinish={onFinish}>
          <FormItem className="InputStyleSearch" label={'Name'} name="name" rules={requiredRule} />

          <FormItem label={'Email Id'} name="emailid" rules={[{ ...requiredRule[0], message: 'Input must be of email', type: 'email' }]} />

          <FormItem label={'Enter Password'} name="userpass" inputType="PASSWORD" rules={passRule} />

          <FormItem label={'Confirm Password'} name="confirmuserpass" inputType="PASSWORD" rules={passRule} />

          <FormItem
            label={'Active'}
            inputType={'SWITCH'}
            checkedChildren="On"
            name="status"
            unCheckedChildren="Off"
            checked={check}
            onChange={onChange}
          />

          <div className="submitButton">
            <FormItem
              inputType="BUTTON"
              tailLayout={tailLayout}
              type="primary"
              buttonStyle={{ width: 180 }}
              loading={loader}
              title="SUBMIT"
              htmlType="submit"
            />
          </div>
        </Form> */}

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={"Name"}
            name="name"
            rules={requiredRule}
            className={styles.item}
          >
            <Input className={styles.inputlook} placeholder="Enter Name" />
          </Form.Item>
          <Form.Item
            label={"Email Id"}
            name="emailid"
            rules={[
              {
                ...requiredRule[0],
                message: "Input must be of email",
                type: "email",
              },
            ]}
            className={styles.item}
          >
            <Input className={styles.inputlook} placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            label={"Enter Password"}
            name="userpass"
            inputType="PASSWORD"
            rules={passRule}
            className={styles.item}
          >
            <Input.Password placeholder="Enter Password" className={styles.inputlook}
            />
          </Form.Item>

          <Form.Item
            label={"Confirm Password"}
            name="confirmuserpass"
            inputType="PASSWORD"
            rules={passRule}
            className={styles.item}
          >
            <Input.Password
              placeholder="Confirm password"
              className={styles.inputlook}
            />
          </Form.Item>

          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "flex-start",
              marginLeft: "30px",
            }}
          >
            <span style={{ marginRight: "8px" }}>Active</span>
            <FormItem
              inputType="SWITCH"
              checkedChildren="On"
              label=""
              name="status"
              unCheckedChildren="Off"
              checked={check}
              onChange={onChange}
            />
          </div>
          <Button
            size="large"
            loading={loader}
            htmlType="submit"
            type="secondary"
            style={{
              backgroundColor: " rgb(24, 78, 119)",
              color: "white",
              width: "10vw",
              border: 0,
              borderRadius: "50px",
              marginLeft: "30px",
            }}
          >
            Submit
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Demo;
