import React from "react";
import { push } from "connected-react-router";
import _ from "lodash";
import { Path } from "path-parser";

import Dashboard from "./containers/home";
import Plan from "./containers/plan/list.js";
import MyPlan from "./containers/plan/myPlane";
import ManagePlan from "./containers/managePlan";
import Users from "./containers/users";
import Subscriptions from "./containers/subscriptions";
import AddUser from "./containers/users/addUser";
import Invoices from "./containers/invoices/index";
import Contactsupport from "./containers/customersupport/index"
import Sessionhistory from "./containers/sessionhistory/index"
import Feedback from "./containers/feedback/index"


import { ReactComponent as Dialerplanner } from "./assets/dialerPlanner.svg";
import { ReactComponent as Dialersms } from "./assets/dialersms.svg";
import { ReactComponent as PlanSvg } from "./assets/plan.svg";
import { LayoutDashboard } from "lucide-react";

import {
  UserAddOutlined,
  IdcardOutlined,
  AppstoreOutlined,
  FileSyncOutlined,
  HistoryOutlined,
  ReadOutlined,
  TeamOutlined,
  LikeOutlined,
  PhoneOutlined 

} from "@ant-design/icons";

const menu = [
  {
    path: "/dashboard",
    name: "Dashboard",
    title: "Dashboard",
    icon: <AppstoreOutlined />,
    key: "home",
    component: <Dashboard />,
    authority: ["user"],
  },

  {
    path: "/myplan",
    name: "My Plan",
    title: "My Plan",
    icon: <FileSyncOutlined />,
    key: "myplan",
    // component: <Plan />,
    component: <MyPlan />,
    authority: ["user"],
  },
  {
    path: "/users",
    name: "Users",
    title: "Users",
    icon: <TeamOutlined />,
    key: "users",
    component: <Users />,
    authority: ["user"],
  },
  {
    path: "/addUser",
    name: "Add User",
    title: "Add User",
    dontShowOnMenu: true,
    key: "addUser",
    component: <AddUser />,
    authority: ["user"],
  },
  {
    path: "/manageplan",
    name: "Manage Plan",
    title: "Manage Plan",
    icon: <IdcardOutlined />,
    key: "manageplan",
    component: <ManagePlan />,
    authority: ["user"],
  },
  {
    path: "/invoices",
    name: "Invoices",
    title: "Invoices",
    icon: <ReadOutlined />,
    key: "invoices",
    component: <Invoices />,
    authority: ["user"],
  },
  
  {
    path: "/sessionhistory",
    name: "Session History",
    title: "Session History",
    icon: <HistoryOutlined />,
    key: "sessionhistory",
    component: <Sessionhistory />,
    authority: ["user"],
  },
  {
    path: "/contactsupport",
    name: "Contact Support",
    title: "Contact Support",
    icon: <PhoneOutlined />,
    key: "contactsupport",
    component: <Contactsupport />,
    authority: ["user"],
  },
  
  {
    path: "/feedback",
    name: "Feedback",
    title: "Customer Feedback",
    icon: <LikeOutlined />,
    key: "feedback",
    component: <Feedback />,
    authority: ["user"],
  },
];

// utils for path

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query));
};

export const getUrlPath = (keyString, params) => {
  if (!params) params = {};

  let keyArr = keyString.split(".");

  let val = _.find(menu, (p) => p.key === keyArr[0]);

  if (!val) {
    return `/`;
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, (p) => p.key === keyArr[1]);
  }

  if (!val) {
    return `/`;
  }

  let queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

  return `${val.path}?${queryString}`;
};

export const getPushPathWrapper = (keyString, params = {}) => {
  let obj = getUrlObject(keyString);

  if (obj) {
    const path = new Path(obj.path);
    return push(path.build(params));
  }

  return "error";
};

export const getUrlParams = (keyString, route) => {
  let obj = getUrlObject(keyString);
  if (obj) {
    const path = new Path(obj.path);

    return path.test(route);
  }

  return { error: true };
};

export const getUrlObject = (keyString) => {
  let keyArr = keyString.split(".");

  let val = _.find(menu, (p) => p.key === keyArr[0]);

  if (!val) {
    return `/`;
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, (p) => p.key === keyArr[1]);
  }

  if (!val) {
    return `/`;
  }

  return val;
};

export default menu;
