import React, { useState, useEffect } from "react";
import { DatePicker, Table, Input } from "antd";
import axios from "axios";
import "antd/dist/antd.css";
import "./session.css";
import { Button } from "antd";
import { useSelector } from "react-redux";
import Request from "../../request";
import moment from "moment";
const { RangePicker } = DatePicker;

const SessionHistory = () => {
  const { currentplanid, customerid } = useSelector((state) => ({
    currentplanid: state?.global?.currentUser?.current_plan_id,
    customerid: state?.global?.associatedcustomer,
  }));
  const [selectedUser, setSelectedUser] = useState("");
  const [fromDate, setfromDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [Filter, setFilter] = useState({ customerid });

  const fetchUserData = async () => {
    const { data, success, message, total_pages } =
      await Request.getsessionhistory({ ...Filter, PageNumber: pageNumber });

    if (success && data.length >= 0) {
      setTotalPage(total_pages);
      setTableData(data);
      return;
    }
    return;
  };

  useEffect(() => {
    fetchUserData();
  }, [pageNumber]);

  const handlePageIncrement = () => {
    if (totalPage > pageNumber) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const handlePageDecrement = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const columns = [
    {
      title: "User",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start",
      dataIndex: "connectiondate",
      key: "connectiondate",
      render: (val, r) => {
        return <>{moment.utc(val).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (e, r) => {
        const momentStartTime = moment(r.connectiondate);
        const momentEndTime = moment(r.endtime);
        const durationDiff = moment.duration(momentEndTime.diff(momentStartTime));
        const hours = Math.floor(durationDiff.asHours());
        const minutes = moment.duration(durationDiff).minutes();
        const seconds = moment.duration(durationDiff).seconds();
      
        return (
          !isNaN(durationDiff.asSeconds()) &&
          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        );
        // return !isNaN(durationInSec) && durationInSec + " " + "sec";
      },
    },

    {
      title: "Host",
      dataIndex: "host",
      key: "host",
    },
    {
      title: "Controller",
      dataIndex: "controller",
      key: "controller",
    },
  ];

  return (
    <div style={{ padding: "40px" }}>
      <div className="search">
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label>Users</label>
          <Input
            placeholder="Enter a username"
            style={{ width: 200 }}
            onChange={(e) => {
              setFilter({
                ...Filter,
                name: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label>Date Range</label>
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <RangePicker
              onChange={(e) => {
                e
                  ? setFilter({
                      ...Filter,
                      startDate: e[0],
                      endDate: e[1],
                    })
                  : setFilter({
                      ...Filter,
                      startDate: null,
                      endDate: null,
                    });
              }}
              // allowClear={false}
            />
            <Button
              type="primary"
              onClick={() => {
                setPageNumber(1);
                pageNumber === 1 && fetchUserData();
              }}
            >
              Show
            </Button>
          </div>
        </div>
      </div>

      <Table dataSource={tableData} columns={columns} pagination={false} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          marginTop: "10px",
        }}
      >
        <button onClick={handlePageDecrement}>Prev Page</button>
        <div style={{ background: "white", padding: "10px" }}>{pageNumber}</div>
        <button onClick={handlePageIncrement}>Next Page</button>
      </div>
    </div>
  );
};

export default SessionHistory;
