import React from 'react'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Store, { history } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import './lightTheme.less'
import App from './containers/app'
import './index.less'
// import { ConsoleSqlOutlined } from '@ant-design/icons';


const { store, persistor } = Store()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        
          <App />

        
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
