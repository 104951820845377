import { useState, lazy, useEffect } from 'react';
import { Card, Space, Spin, Tooltip, Result, Radio, notification, message, Table, Tabs, Button, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Request from "../../request";
import './styles.less'
import { AudioOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { useParams,useLocation } from 'react-router';
import { push } from 'connected-react-router';

const HomeView = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const payerId = searchParams.get('PayerID');
  const dispatch = useDispatch()
  const [state, setState] = useState()
  const [loading, setLoader] = useState(false)
  console.log(token,payerId,'I')

  const { currentUser } = useSelector((state) => ({
    currentUser: state.global.currentUser
  }))

  const checkStatus = async () => {
    setLoader(true)
    let { user, success, message, data } = await Request.checkStatus({ token: token })
    setLoader(false)

    if (success) {

      console.log(data,"PAYMENT STATUS")
      if (data.status == 'COMPLETE') {
      window.location.href = `/payment/success/${data.payer_id}`
      }
      else if (data.status == 'FAILED') {
        // window.location.href = `/payment/failed/${token}`
      }
      // else {
      //   // window.location.href = "/*"
      //   window.location.href = `/payment/failed/${id}`
      // }
      // }
       else {
        // window.location.href = `/`
      }
    }
  }

  useEffect(() => {
    console.log(token,"USEEEEFFFEECCTTT")
    checkStatus()

  }, [token])

  return <div>
    <Spin tip="Loading" size="large" style={{ height: '100vh' }}>
      <div className="content">
        We are processing your payment please don't go back or cancel here.
      </div>
    </Spin>
  </div>
}

export default HomeView;
