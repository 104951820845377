import { useState, lazy, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./styles.less";
import styles from "./styles.module.css";
import { MoreOutlined } from "@ant-design/icons";

import Request from "../../request";
import _ from "lodash";
import async, { log } from "async";
import { Progress, Space } from "antd";
import moment from "moment";
import { ReactComponent as TotalCalls } from "../../assets/totalCalls.svg";
import {
  notification,
  Drawer,
  Input,
  Tag,
  Tooltip,
  Tabs,
  Modal,
  Skeleton,
  Button,
  Select,
} from "antd";

const HomeView = (props) => {
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const [currentPlanUsage, setCurrentPlanUsage] = useState(0);
  const [online, setOnline] = useState([]);
  const [week, setweek] = useState([]);
  const [month, setmonth] = useState([]);
  const [alltime, setalltime] = useState([]);
  const [todaysession, settodaysession] = useState([]);
  const [ongoingsessions, setongoingsessions] = useState([]);

  const {
    currentUser,
    todaysCallbacks,
    associated,
    allowedSessions,
    sessioncounter,
    currentpplanid,
  } = useSelector((state) => ({
    currentUser: state?.global?.currentUser,
    todaysCallbacks: state?.global?.todaysCallbacks,
    associated: state?.global?.associatedcustomer,
    allowedSessions:
      state?.global?.currentUser?.sessionsDetail?.allowed_sessions,
    sessioncounter:
      state?.global?.currentUser?.sessionsDetail?.sessions_counter,
    currentpplanid: state?.global?.currentUser?.current_plan_id,
  }));
  var pExpiry = moment(currentUser?.planexpiry).format("L");
  var today = moment(new Date()).format("DD/MM/YYYY");

  useEffect(() => {
    fetchData();
    sessions();
    ongoing();
  }, []);

  const fetchData = () => {
    const data = { associatedcustomer: associated };

    Request.getOnline(data)
      .then((res) => {
        const allowedSessionsNumber = parseInt(allowedSessions);
        const sessionCounterNumber = parseInt(sessioncounter);
        const usagePercentage =
          (sessionCounterNumber / allowedSessionsNumber) * 100;
        setCurrentPlanUsage(usagePercentage.toFixed(2));
        setOnline(res.data);

        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const sessions = () => {
    const data = { customerid: associated };
    console.log(currentpplanid);
    Request.getSessions(data)
      .then((res) => {
        setweek(res?.data?.lastWeek);
        setmonth(res?.data?.thisMonth);
        setalltime(res?.data?.total);
        settodaysession(res?.data?.today);
        console.log(res);
        // console.log(res.data.lastWeek)
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const ongoing = () => {
    const data = { customerid: associated };
    console.log(currentpplanid);
    Request.getongoing(data)
      .then((res) => {
        console.log(res);
        setongoingsessions(res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <div
        className={styles.mobile}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "40px",
          marginTop: "1.5rem",
        }}
      >
        <div>
          <div className="center" style={{ marginBottom: "1.5rem" }}>
            <div>Current Plan Usage</div>

            <div
              style={{
                textAlign: "center",
                marginTop: "1em",
              }}
            >
              <Space>
                <Progress
                  type="circle"
                  percent={currentPlanUsage}
                  size={200}
                  strokeColor="#2A76AC"
                  width={100}
                />
              </Space>
            </div>
          </div>
          <div
            className="center"
            style={{ marginBottom: "1.5rem", height: "376px" }}
          >
            <div>Online User(s)</div>
            <div>
              <table className={styles.datatable}>
                <thead>
                  <tr>
                    <th colSpan="3"></th>
                  </tr>
                </thead>
                <tbody>
                  {online.map((item, index) => (
                    <tr key={index} className={styles.datarow}>
                      <td>
                        <div
                          style={{
                            background: "#84BBC3",
                            borderRadius: "50px",
                            width: "24px",
                            height: "24px",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ color: "white" }}>
                            {item.name.charAt(0)}
                          </span>
                        </div>
                      </td>

                      <td>{item.name}</td>

                      {/* <td>00:00:45</td> */}
                      <td>
                        <MoreOutlined />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <div className="center" style={{ marginBottom: "1.5rem" }}>
            <div>Sessions Today</div>
            <div className={styles.bigblue}>{todaysession}</div>
          </div>
          <div
            className="center"
            style={{ marginBottom: "1.5rem", height: "376px" }}
          >
            <div>Ongoing Sessions</div>
            <div>
              <table className={styles.datatable}>
                <thead>
                  <tr>
                    <th colSpan="3"></th>
                  </tr>
                </thead>
                <tbody>
                  {ongoingsessions.map((item, index) => (
                    <tr key={index} className={styles.datarow}>
                      <td>
                        <div
                          style={{
                            background: "#84BBC3",
                            borderRadius: "50px",
                            width: "24px",
                            height: "24px",
                            textAlign: "center",
                          }}
                        >
                          <span style={{ color: "white" }}>
                            {item.name.charAt(0)}
                          </span>
                        </div>
                      </td>

                      <td>{item.name}</td>

                      {/* <td>00:00:45</td> */}
                      <td>
                        <MoreOutlined />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <div className="center" style={{ marginBottom: "1.5rem" }}>
            <div>Sessions Last Week</div>
            <div className={styles.bigblue}>{week}</div>
          </div>

          <div className="center" style={{ marginBottom: "1.5rem" }}>
            <div>Sessions Current Month</div>
            <div className={styles.bigblue}>{month}</div>
          </div>

          <div className="center" style={{ marginBottom: "1.5rem" }}>
            <div>Sessions All Time</div>
            <div className={styles.bigblue}>{alltime}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeView;
