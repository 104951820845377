const initialState = {
    sessionCall: null,
    endpoint: null,
    destination: null,
    incomingStatus: null,
    outgoingStatus: null,
    terminated: null,
    failed: null,
    registrationFailed: null,
    unRegistered: true,
    registered: null,
    destinationFromContacts: null,
    newConferenceNumber: null,
    dispositionNumber: null,
    callTimer: null,
    intervalId: null,
    intervalRan: false,
    displayTime: '00:00:00',
}

export default (state = initialState, action) => {
    switch (action.type) {

        case 'SET_ENDPOINT':
            return {
                ...state,
                endpoint: action.payload,
            }
        case 'SET_CALL':
            return {
                ...state,
                sessionCall: action.payload,
            }
        case 'SET_NUMBER':
            return {
                ...state,
                destination: action.payload,
            }
        case 'SET_NUMBER_FROM_CONTACTS':
            return {
                ...state,
                destinationFromContacts: action.payload,
            }
        case 'OUTGOING_STATUS':
            return {
                ...state,
                outgoingStatus: action.payload,
            }
        case 'INCOMING_STATUS':
            return {
                ...state,
                incomingStatus: action.payload,
            }
        case 'TERMINATE':
            return {
                ...state,
                terminated: action.payload,
            }
        case 'FAILED':
            return {
                ...state,
                failed: action.payload,
            }
        case 'REGIS_FAILED':
            return {
                ...state,
                registrationFailed: action.payload,
            }
        case 'UNREGISTERED':
            return {
                ...state,
                unRegistered: action.payload,
            }
        case 'REGISTERED':
            return {
                ...state,
                registered: action.payload,
            }
        case 'SET_CONFERENCE_NUMBER':
            return {
                ...state,
                newConferenceNumber: action.payload,
            }
        case 'SET_DISPOSITION_NUMBER':
            return {
                ...state,
                dispositionNumber: action.payload,
            }
        case 'SET_CALL_TIMER':
            return {
                ...state,
                callTimer: action.payload,
            }
        case 'SET_INTERVAL_ID':
            return {
                ...state,
                intervalId: action.payload,
            }
        case 'SET_INTERVAL_RAN_ONCE':
            return {
                ...state,
                intervalRan: action.payload,
            }
        case 'SET_CALL_DISPLAY_TIME':
            return {
                ...state,
                displayTime: action.payload,
            }

        default:
            return state
    }
}