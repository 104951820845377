import React from "react";
import { useForm, Controller } from "react-hook-form";
import Request from "../../request";
import _ from "lodash";
import "./support.css"
import { useSelector } from "react-redux";
import { message, notification } from "antd";


const ContactForm = () => {

    const {customerid}=useSelector((state)=>({customerid:state.global.associatedcustomer}))
  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName:"",
      mobileNumber:"",
      email:"",
      message:"",
    },
  })
  
  const onSubmit = (data) => {
    console.log(data,"7777777777777");
    
    const sendContact = () => {
      
      const apiData = {
        customerid: customerid,
        name: `${data.firstName} ${data.lastName}`,
        mobileNumber: data.mobileNumber,
        email: data.email,
        message: data.message,
      };

      
      Request.sendcontactsupport(apiData)
        .then((res) => {
          console.log(res);
          resetField("firstName")
          resetField("lastName")
          resetField("mobileNumber")
          resetField("email")
          resetField("message")
          
        
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          
          notification.success({
            message: "Submitted",
            description: "Your form has been successfully submitted.",
          });
         
        });
    };

    sendContact();
  };


  return (
    <form className="supportForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="outeralign">
      <div className="inneralign">
        <label htmlFor="firstName">First Name</label>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: "First name is required" }}
          render={({ field }) => <input {...field} placeholder="Enter your first name" />}
        />
        {errors.firstName && <p>{errors.firstName.message}</p>}
      </div>

      <div className="inneralign">
        <label htmlFor="lastName">Last Name</label>
        <Controller
        
          name="lastName"
          control={control}
          rules={{ required: "Last name is required" }}
          render={({ field }) => <input {...field} placeholder="Enter your last name"/>}
        />
        {errors.lastName && <p>{errors.lastName.message}</p>}
      </div>
</div>

<br></br>
<div className="outeralign">
      <div className="inneralign">
        <label htmlFor="mobileNumber">Mobile Number</label>
        <Controller
          name="mobileNumber"
          control={control}
          rules={{ required: "Mobile number is required" }}
          render={({ field }) => <input {...field} placeholder="Enter your mobile number"/>}
        />
        {errors.mobileNumber && <p>{errors.mobileNumber.message}</p>}
      </div>

      <div className="inneralign">
        <label htmlFor="email">Email</label>
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            },
          }}
          render={({ field }) => <input {...field} placeholder="Enter your email id"/>}
        />
        {errors.email && <p>{errors.email.message}</p>}
      </div>
</div>
<br></br>
      <div className="inneralign">
        <label htmlFor="message">Message</label>
        <Controller
          name="message"
          control={control}
          rules={{ required: "Message is required" }}
          render={({ field }) => <textarea {...field} placeholder="Enter your query"/>}
        />
        {errors.message && <p>{errors.message.message}</p>}
      </div>
<br></br>
      <button type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;
