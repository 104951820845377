import React, { useRef, useState } from "react";
import styles from "../styles.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { confirmBox } from "../../../utils";
import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { useEffect } from "react";
import Request from "../../../request";
import async from "async";

export default function Component({ userDetails, notification, moment }) {
  const [addUser, setAddUser] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoader] = useState(false);
  const [sessionId, setSessionId] = useState();

  const addUserRef = useRef(null);

  const { currentUser, todaysCallbacks, associatedcustomer } = useSelector(
    (state) => ({
      currentUser: state.global.currentUser,
      todaysCallbacks: state.global.todaysCallbacks,
      associatedcustomer: state.global.associatedcustomer,
    })
  );

  const onFinish = async (values) => {
    values.preventDefault();
    const formData = new FormData();
    formData.append("user", addUser);
    // const { success, data, error, message} = await Request
  };

  const togglePopUp = (visibility, width, show, flex, padding) => {
    if (addUserRef !== null) {
      addUserRef.current.style.visibility = visibility;
      addUserRef.current.style.maxWidth = width;
      addUserRef.current.style.padding = padding;
      setShow(show);
      addUserRef.current.style.flex = flex;
    }
  };

  const addSubscriptions = async () => {
    var updatedAllowedConnections =
      currentUser?.planDetails?.planallowedconnection * addUser;
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var remainingDays =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    const monthDays = moment().daysInMonth();
    var monthAmount;
    monthAmount = (
      (currentUser?.planDetails?.amount / monthDays) *
      (remainingDays + 1) *
      addUser
    ).toFixed(2);
    if (monthAmount < 1) {
      monthAmount = 1.0;
    }
    const value = {
      order_amount: monthAmount,
      planName: currentUser?.planDetails?.planname,
      customer_id: associatedcustomer,
      customer_name: currentUser?.billingname,
      customer_email: currentUser?.emailid,
      customer_phone: currentUser?.contactnumber,
      planId: currentUser?.planDetails?.planid,
      updatedAllowedConnections: updatedAllowedConnections,
      addedSubscriptions: addUser,
    };

    confirmBox(useDispatch, {
      message: `For ${addUser}, You will pay ${monthAmount}.Allowed Connections: ${updatedAllowedConnections}.`,
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } =
          await Request.addSubscritions(value);
        setLoader(false);
        if (success) {
          var newData = [];
          async.forEach(data, (val, cb) => {
            if (val?.rel == "approve") {
              console.log(val, "HELLO");
              window.location.href = val?.href;
              cb();
            } else {
              cb();
            }
          });
          notification.success({
            message: message || "Success",
          });
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };

  const paymentPage = () => {
    if (sessionId?.toString().length > 1) {
      console.log("I rannn");
      let cashfree = new cashfreeSandbox.Cashfree(sessionId);
      cashfree.redirect();
    }
  };

  useEffect(() => {
    paymentPage();
  }, [sessionId]);
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.currentPlanSec}`}>
        <h2>Your Current Plan</h2>
        <ul>
          <li>
            <p>Current Users</p>
            <p>{userDetails?.subscriptions || "NA"}</p>
          </li>
          <li>
            <p>Expiring On</p>
            <p>
              {moment(userDetails?.planexpiry).format("DD-MM-YYYY") || "NA"}
            </p>
          </li>
        </ul>
        <button
          onClick={() => togglePopUp("visible", "100%", true, "1", "1em 1.5em")}
        >
          ADD USERS
        </button>
      </div>
      <div ref={addUserRef} className={`${styles.yourPlansec}`}>
        {show && (
          <>
            <div className={styles.closeBtn}>
              <CloseOutlined
                style={{
                  borderRadius: "5px",
                  background: "#F2F2F2",
                  padding: ".3em",
                  cursor: "pointer",
                  fontSize: 25,
                  fontWeight: 900,
                  display: "grid",
                  placeContent: "center",
                }}
                onClick={() => {
                  togglePopUp("hidden", "0", false, "0", "0 0");
                }}
              />
            </div>
            {currentUser?.planDetails?.planname !== "trial" ? (
              <>
                <form
                  className={`${styles.form}`}
                  onSubmit={(e) => onFinish(e)}
                >
                  <div className={`${styles.formItem}`}>
                    <input
                      type="text"
                      name="addUser"
                      value={addUser}
                      onChange={(e) => setAddUser(e.target.value)}
                      placeholder="Add User"
                    />
                  </div>
                  <div className={`${styles.formItem}`}>
                    <button
                      type="submit"
                      onClick={addSubscriptions}
                      style={{
                        paddingTop: 14,
                        paddingBottom: 14,
                        borderRadius: 7,
                      }}
                    >
                      ADD
                    </button>
                  </div>
                </form>
                <p>*Will be added in current Users</p>
              </>
            ) : (
              <h3>*You first need to buy a Plan before adding any user.</h3>
            )}
          </>
        )}
      </div>
    </div>
  );
}
