import React, { useState, useEffect } from "react";
import { Button } from "antd";
import InvoiceCard from "./invoiceCard";
import "./invoice.css";
import { useSelector } from "react-redux";
import Request from "../../request";
import _ from "lodash";

const InvoiceList = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [visibleCards, setVisibleCards] = useState(6);

  const { customerid } = useSelector((state) => ({
    customerid: state.global.associatedcustomer,
  }));

  const fetchInvoiceData = async () => {
    const { data, success, error } = await Request.getinvoices({ customerid });

    if (success && data) {
      let InvoiceArr = [...data?.planInvoice, ...data?.subscriptionInvoice];
      setInvoiceData(InvoiceArr);
    }
  };

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  const handleShowMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 6);
  };

  return (
    <div className="back">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1em",
          justifyContent: "center",
        }}
      >
        {invoiceData.length > 0
          ? invoiceData
              .slice(0, visibleCards)
              .map((data, index) => <InvoiceCard invoiceData={data} />)
          : "No Invoce Found!"}
      </div>
      {visibleCards < invoiceData.length && (
        <div style={{ textAlign: "center", marginTop: "16px" }}>
          <Button type="primary" onClick={handleShowMore}>
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

export default InvoiceList;
