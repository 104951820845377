import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { notification } from "antd";
import Request from "../../request";
import moment from "moment";

// components ::
import CurrentPlan from "./elements/currentPlan";
import OtherAvailablePlans from "./elements/otherAvailablePlans";

export default function Component() {
  useEffect(() => {
    funcApi();
  }, []);

  const [userDetails, setUserDetails] = useState({});

  const funcApi = async () => {
    const { data, success, error, message } = await Request.getProfile();
    if (success && data) {
      return setUserDetails(data);
    }
    notification.error({
      message: message || "Some Error Occurred!",
    });
    return setUserDetails({});
  };
  return (
    <>
      <CurrentPlan
        userDetails={userDetails}
        moment={moment}
        notification={notification}
      />
      <OtherAvailablePlans />
    </>
  );
}
