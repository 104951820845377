import React, { useState, useEffect } from 'react'
import { Button, Typography, Input, Space, notification, Select, Switch, InputNumber } from 'antd';
import './styles.less'
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import Request from '../../request';
import async from 'async'
import { push } from 'connected-react-router';
import { ArrowRightOutlined, MailOutlined, MobileOutlined, UserOutlined, WalletOutlined, LockOutlined, UserSwitchOutlined, HomeOutlined, CreditCardOutlined, MessageOutlined, UndoOutlined, ItalicOutlined } from '@ant-design/icons';
import _ from 'lodash'
import { isNormalInteger } from '../../utils';
import { ReactComponent as ImageThumb } from '../../assets/LoginPage.svg'

const { Text } = Typography
const { Option } = Select

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [form3] = Form.useForm()

    const [loader, setLoader] = useState(false)
    const [countries, setCountries] = useState([])
    const [login, setLoginRun] = useState(true)
    const [num, setNum] = useState()
    const [signupdata, setSignupdata] = useState()
    const [getStarted, setGetStarted] = useState()
    const [captchaStatus, setCaptchaStatus] = useState(false)
    const [verified, setVerified] = useState(false)
    const [typeOtp, setTypeOtp] = useState(false)
    const [code, setCode] = useState("");
    const [captchaChars, setCaptchaChars] = useState([]);
    const [valData, setValdata] = useState();

    const onFinish = async (valData) => {
        if (captchaStatus == true) {
            let gstRegex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
            let phoneRegex = new RegExp(/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/);
            let subRegex = new RegExp(/^\+?(0|[1-9]\d*)$/);
            var { contactnumber, gstvat, subscriptions } = valData
    
            if (subRegex.test(subscriptions) != true) {
                notification.error({
                    message: "Invalid Subscribers Number"
                })
                setSignupdata('')
                return;
            }
            if (gstvat != null && gstvat.length != 0) {
                if (gstRegex.test(gstvat) != true) {
                    notification.error({
                        message: "Invalid GST Number"
                    })
                    return;
                }
            }
            if (phoneRegex.test(contactnumber) != true) {
                notification.error({
                    message: "Invalid Mobile Number"
                })
                return;
            }
    
            else {
                setLoader(true)
                let obj = {}
                obj = {
                    auth: {
                        active: "Y",
                        ...valData
                    },
                    businessname: valData?.businessname
                }
                let { user, token, success, msg, data } = await Request.signup(obj)
                setLoader(false)
                if (success) {
                    notification.success({
                        message: msg,
                    })
                    form.resetFields()
                }
                else {
                    createCaptcha()
                    notification.error({
                        message: msg || "Failed",
                        // description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
                    })
                }
                setLoader(false)
            }
        } else {
            let gstRegex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
            let phoneRegex = new RegExp(/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/);
            let subRegex = new RegExp(/^\+?(0|[1-9]\d*)$/);
            var { contactnumber, gstvat, subscriptions } = valData

            if (subRegex.test(subscriptions) != true) {
                notification.error({
                    message: "Invalid Subscribers Number"
                })
                setSignupdata('')
                return;
            }
            if (gstvat != null && gstvat.length != 0) {
                if (gstRegex.test(gstvat) != true) {
                    notification.error({
                        message: "Invalid GST Number"
                    })
                    return;
                }
            }
            if (phoneRegex.test(contactnumber) != true) {
                notification.error({
                    message: "Invalid Mobile Number"
                })
                return;
            }

            setValdata(valData)

            if (code === valData?.enteredcaptcha) {
                setCaptchaStatus(true)
                notification.success({
                    message: "Verified"
                })
            } else {
                form3.resetFields('')
                notification.error({
                    message: "Invalid Captcha",
                    // description: JSON.stringify(data)?.replace('[', '')?.replace('{', '')?.replace(']', '')?.replace('}', '')
                })
            }

        }

    }

    useEffect(() => {
        // if (captchaStatus) {
        createCaptcha();
        // }
    }, []);


    const createCaptcha = () => {
        const charsArray =
            "abcdefghijklmnopqrstuvwxyz0123456789@!#$%^&*abcdefghijklmnopqrstuvwxyz@!#$%^&ABCDEFGHIJKLMNOPQRSTUVWXYZ`@!#$%^&*`";
        const lengthOtp = 6;
        const captcha = [];
        for (let i = 0; i < lengthOtp; i++) {
            const index = Math.floor(Math.random() * charsArray.length + 1);
            if (captcha.indexOf(charsArray[index]) === -1)
                captcha.push(charsArray[index]);
            else i--;
        }
        console.log(captcha, "HHHHHHHHHHH")
        setCaptchaChars(captcha);
        setCode(captcha.join(""));
    };



    const onChange = (value) => {
        form.setFieldsValue({ countrycode: value });
    };
    const getFunction = async () => {
        let { user, token, success, data } = await Request.getCountries()
        if (success) {
            let setOnlyCountries = []
            async.forEach(data, (value, cb) => {
                if (!value?.default_blocked) {
                    setOnlyCountries.push(value)
                }
                cb()
            }, () => {
                setCountries(() => setOnlyCountries)
            })
        } else {
            setCountries(() => [])

        }
    }
    useEffect(() => {
        getFunction()
        form.setFieldsValue({ country: 'IN' });
    }, [])
    const requiredRule = [{ required: true, message: '*Please input field' }]
    const fNameRule = [{ min: 2, message: 'minimum 2 characters' }, { required: true, message: '*Please input field' }]
    const emailRule = [{ type: 'email', message: 'Not A Valid Email-ID!' }, { required: true, message: '*Please input field' }]
    const mobileRule = [{ max: 10, message: 'Invalid Number' }, { required: true, message: '*Please input field' }]
    const otpRules = [{ max: 6, message: 'Invalid Number' }, { required: true, message: '*Please input field' }]
    const passRule = [{ min: 8, max: 16, message: 'Password should be between 8-16 characters ' }, { required: true, message: '*Please input field' }]
    const captchaRule = [{ max: 6, message: '*Invaid Captcha' }, { required: true, message: '*Please input field' }]


    const prefixSelector = (
        <Form.Item name="country" noStyle={true} >
            <Select defaultValue='IN' style={{ width: '70%' }} className="formFieldUnderline" onChange={onChange}>
                {_.map(countries, data => {
                    return <Option value={data?.countrycode}>{data?.countryname}</Option>
                })}
            </Select>
        </Form.Item>

    );

    const number = (e) => {
        console.log(e.key, "KKKKKKKK")
        var x = e.which || e.keycode;
        if ((x >= 48 && x <= 57)) {
            return true
        } else {
            return e.key.replace(e, '')
        }
    }
    const alreadyVerified = () => {
        setVerified(true)
    }
    const onFinish2 = async (valData) => {
        if (!valData?.otp) {
            let { user, token, success, message, data } = await Request.sendOTP(valData)
            if (success) {
                notification.success({
                    message: message || "Success",
                })
                setTypeOtp(true)
            }
        }
        else {
            let { user, token, success, message, data } = await Request.verifyOTP(valData)
            if (success) {
                notification.success({
                    message: message || "Success",
                })
                setVerified(true)
            } else {
                notification.error({
                    message: message || "Failed",
                })
            }
        }
    }
    const toVerify = () => {
        setVerified(false)
    }
    return (
        <>
            {(!verified) ?
                <>
                    <Form className='form' form={form2} onFinish={onFinish2} size='large' hideRequiredMark={false} layout='vertical'>
                        <Form.Item style={{ marginTop: '1rem' }} name='emailid' rules={emailRule}>
                            <Input className="formFieldUnderline" prefix={<MailOutlined className="login-signup-icon" />} type="email" placeholder='Email ID' />
                        </Form.Item>

                        {typeOtp && <Form.Item style={{ marginBottom: '-2rem' }} name='otp' rules={otpRules} >
                            <Input className="formFieldUnderline" prefix={<MobileOutlined className="login-signup-icon" />}
                                onKeyUp={number} value={num} type='number' placeholder='Enter OTP' />
                        </Form.Item>}

                        {typeOtp ? <Form.Item style={{ marginBottom: '-2rem', width: '80%' }}>
                            <Button size='large' loading={loader} htmlType='submit' type='secondary' style={{ backgroundColor: 'rgb(24, 78, 119)', color: 'white', border: 0, borderRadius: '7px', marginBottom: '1.45rem', width: '80%' }}>Verify Email</Button>
                        </Form.Item> : <Form.Item style={{ marginBottom: '-2rem', width: '80%' }}>
                            <Button size='large' loading={loader} htmlType='submit' type='secondary' style={{ backgroundColor: 'rgb(24, 78, 119)', color: 'white', marginTop: '-10px', border: 0, borderRadius: '7px' }}>Send OTP</Button>
                        </Form.Item>}
                    </Form>

                    <div style={{ color: 'black' }}> Already Verified Email?<Button onClick={alreadyVerified} style={{ color: '#76C893', boxShadow: 'none', border: 0, fontWeight: 600, paddingLeft: '5px' }}><span style={{color:"#76C893",marginLeft:'2px'}}>Signup</span></Button>
                    </div>
                </> : <></>}

            {verified &&
                <div className='signupExpand' style={{height:" 37vh"}}>
                    <Form className='form' form={form} onFinish={onFinish} size='large' hideRequiredMark={false} layout='vertical'>
                        <div className='inputWidth '>
                            <Form.Item style={{ marginBottom: '-2rem' }} name='emailid' rules={emailRule}>
                                <Input className="formFieldUnderline" prefix={<MailOutlined className="login-signup-icon" />} type="email" placeholder='Verified Email ID' />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: '-2rem' }} name='billingname' >
                                <Input className="formFieldUnderline" prefix={<UserOutlined className="login-signup-icon" />} placeholder='Full Name' />
                            </Form.Item>

                            <Form.Item style={{ marginBottom: '-2rem' }} name='companyname'>
                                <Input className="formFieldUnderline" prefix={<WalletOutlined className="login-signup-icon" />} placeholder='Business Name' />
                            </Form.Item>

                            <Form.Item style={{ marginBottom: '-2rem' }} name='gstvat' >
                                <Input className="formFieldUnderline" prefix={<CreditCardOutlined className="login-signup-icon" />} placeholder='GST/VAT No.' />
                            </Form.Item>

                            <Form.Item style={{ marginBottom: '-2rem' }} name='companyaddress' rules={requiredRule}>
                                <Input className="formFieldUnderline" prefix={<HomeOutlined className="login-signup-icon" />} placeholder='Company Address' />
                            </Form.Item>

                            <Form.Item style={{ marginBottom: '-2rem' }} name='contactnumber'
                                rules={mobileRule}
                            >
                                <Input className="formFieldUnderline" prefix={<MobileOutlined className="login-signup-icon" />}
                                    onKeyUp={number} value={num} type='number' placeholder='Phone No.' />

                            </Form.Item>

                            <Form.Item style={{ marginBottom: '-2rem' }} name='subscriptions' rules={requiredRule}>
                                <Input type="number" className="formFieldUnderline" style={{ width: "100%" }} placeholder='Subscriptions' prefix={<UserSwitchOutlined className="login-signup-icon" />} />
                            </Form.Item>

                            <Form.Item name='userpass'style={{marginBottom:'-18px'}} rules={passRule} hasFeedback>
                                <Input className="formFieldUnderline"  prefix={<LockOutlined className="login-signup-icon" />} type="password" placeholder='Password' />
                            </Form.Item>
                        </div>
                        {prefixSelector}
                        {!captchaStatus &&
                            <>  <div style={{ marginTop: '2rem' }}>
                                <h2 className='blur'><b><i>{captchaChars}</i></b></h2>
                            </div>

                                <Form.Item name='enteredcaptcha' hasFeedback>
                                    <Input className="formFieldUnderline" placeholder='Captcha' />
                                </Form.Item>
                                <Form.Item style={{ width: '80%',marginTop:'-50px' }}>
                                    <Button size='large' loading={loader} htmlType='submit' type='secondary' style={{ backgroundColor: 'rgb(24, 78, 119)', color: 'white',marginTop:'-4rem', border: 0, borderRadius: '7px' }}>Verify Captcha</Button>
                                    <Button size='large' loading={loader} onClick={createCaptcha} type='secondary' style={{ backgroundColor: 'rgb(24, 78, 119)', color: 'white', marginTop: '1rem', marginLeft: '1rem', border: 0, borderRadius: '7px', width: '20%' }}><UndoOutlined /></Button>
                                </Form.Item></>}

                        {captchaStatus &&
                            <Form.Item style={{ width: '80%' }}>
                                <Button size='large' loading={loader} htmlType='submit' type='secondary' style={{ backgroundColor: 'rgb(24, 78, 119)', color: 'white', marginTop: '2rem', border: 0, borderRadius: '7px'}}>Get Started</Button>
                            </Form.Item>}

                        <div style={{ color: 'black', float: 'right' }}> Verify Email?<Button onClick={toVerify} style={{ color: '#76C893', boxShadow: 'none', border: 0, fontWeight: 600, paddingLeft: '5px' }}><span style={{color:'#76C893'}}>Click here</span></Button>
                        </div>
                    </Form>
                </div>
            }
        </>
    );
}

export default Login