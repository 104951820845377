import React, { useState } from "react";
import {
  Button,
  Typography,
  Input,
  Space,
  notification,
  Select,
  Switch,
  Divider,
} from "antd";
import "./styles.less";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import Request from "../../request";
import { push } from "connected-react-router";
import {
  ArrowRightOutlined,
  LockOutlined,
  UndoOutlined,
  UserOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { isNormalInteger } from "../../utils";
import { ReactComponent as ImageThumb } from "../../assets/LoginPage.svg";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import backImg from "../../assets/loginBack.svg";
import rdText from "../../assets/Logo (1).svg";
import rdTextLogin from "../../assets/Logo.svg";
import lapDesk from "../../assets/LDAO.svg";
import Signup from "../signup";
import ForgotPassword from "./ForgotPassword";
import { useEffect } from "react";
const { Text } = Typography;
const { Option } = Select;

const Login = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form3] = Form.useForm();

  const [loader, setLoader] = useState(false);
  const [login, setLoginRun] = useState(true);
  const [getImg, setImg] = useState(true);
  const [forgotPassWord, setForgotPassword] = useState(false);
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [valData, setValData] = useState();
  const [code, setCode] = useState("");
  const [captchaChars, setCaptchaChars] = useState([]);
  const [captchaValidater, setCaptchaValidater] = useState(false);

  const onFinish = async (valData) => {
    valData = {
      ...valData,
    };
    setLoader(true);
    setValData(valData);
    let { user, token, success, message, data, loginAttempts } =
      await Request.login(valData);
    if (loginAttempts >= 3) {
      setCaptchaStatus(true);
    }
    if (success) {
      dispatch({
        type: "SET_AUTH_TOKEN",
        payload: data.token,
      });
      dispatch({
        type: "SEND_CASSOCIATED",
        payload: data.associatedcustomer,
      });
      Request.getProfile().then(({ error, data: profileData, message }) => {
        if (!error && profileData) {
          dispatch({
            type: "SET_CURRENT_USER",
            payload: { ...profileData },
          });
          dispatch(push("/dashboard"));
          notification.success({
            message: message,
          });
        } else {
          notification.error({
            message: message || "Failed",
            description: JSON.stringify(profileData)
              ?.replace("[", "")
              ?.replace("{", "")
              ?.replace("]", "")
              ?.replace("}", ""),
          });
        }
      });
    } else {
      notification.error({
        message: message || "Failed",
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    if (captchaStatus) {
      createCaptcha();
    }
  }, [captchaStatus]);

  const createCaptcha = () => {
    const charsArray =
      "abcdefghijklmnopqrstuvwxyz0123456789@!#$%^&*abcdefghijklmnopqrstuvwxyz@!#$%^&ABCDEFGHIJKLMNOPQRSTUVWXYZ`@!#$%^&*`";
    const lengthOtp = 6;
    const captcha = [];
    for (let i = 0; i < lengthOtp; i++) {
      const index = Math.floor(Math.random() * charsArray.length + 1);
      if (captcha.indexOf(charsArray[index]) === -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    console.log(captcha, "HHHHHHHHHHH");
    setCaptchaChars(captcha);
    setCode(captcha.join(""));
  };

  const validateCaptcha = async (val) => {
    if (code === val?.enteredcaptcha) {
      setCaptchaStatus(false);
      form3.resetFields("");
      notification.success({
        message: "Verified",
      });
    } else {
      form3.resetFields("");
      notification.error({
        message: "Invalid Captcha!Try Again",
      });
    }
  };
  const captchaCheck = async (e) => {
    console.log(e.target.value, "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
    var emailid = e.target.value;
    let { user, token, success, message, data, captchaStatus } =
      await Request.captcha({ emailid: emailid });
    if (success) {
      setCaptchaStatus(data?.captchaStatus);
    }
  };

  const emailRule = [
    { type: "email", message: "Not A Valid Email-ID!" },
    { required: true, message: "Please Enter your E-mail!" },
  ];
  const requiredRule = [
    { required: true, message: "Please Enter your password" },
  ];

  return (
    <div className="LoginParentDiv">
      <div className="LoginSubDiv">
        <div
          style={{
            width: "100vw",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100vh",
            margin: "0 auto",
            backgroundImage: `url(${backImg})`,
          }}
        >
          <img
            src={rdText}
            style={{
              left: "7%",
              top: "8%",
              position: "absolute",
              width: "15vw",
            }}
          />

          <img
            src={lapDesk}
            style={{
              bottom: "0",
              left: "8%",
              position: "absolute",
              zIndex: "1",
              width: "35vw",
            }}
          />
        </div>
        {/* <img
                    src={right}
                    style={{ left: '74%', position: "absolute", height: '100vh' }}
                /> */}
      </div>
      <div className="loginWrapper loginWrapper1">
        {!forgotPassWord ? (
          <div className="loginContainer" style={{ background: "white" }}>
            <img
              src={rdTextLogin}
              style={{
                left: "70.5%",
                top: "17%",
                position: "absolute",
                width: "18vw",
              }}
            />

            <div className="buttonDiv" style={{ marginTop: "10rem" }}>
              <div className={login ? "selectedDiv1" : "unselectedDiv1"}>
                <Button
                  ghost={!login}
                  onClick={() => {
                    setLoginRun(true);
                    setImg(true);
                  }}
                  style={{
                    boxShadow: "none",
                    border: "none!important",
                    backgroundColor: "transparent",
                  }}
                  className={
                    login ? "selectedDivButton1" : "unselectedDivButton1"
                  }
                >
                  Sign In
                </Button>
              </div>
              <div className={!login ? "selectedDiv1" : "unselectedDiv1"}>
                <Button
                  ghost={login}
                  onClick={() => {
                    setLoginRun(() => false);
                    setImg(false);
                  }}
                  style={{
                    boxShadow: "none",
                    border: "none!important",
                    backgroundColor: "transparent",
                  }}
                  className={
                    !login ? "selectedDivButton1" : "unselectedDivButton1"
                  }
                >
                  Sign Up
                </Button>
              </div>
            </div>
            <div className="displayFlex">
              {login ? (
                <div>
                  <Form
                    className="form"
                    form={form}
                    onFinish={onFinish}
                    size="large"
                    layout="vertical"
                  >
                    <div className="inputWidth">
                      <Form.Item name="emailid" rules={emailRule}>
                        <Input
                          className="formFieldUnderline"
                          prefix={
                            <UserOutlined className="login-signup-icon" />
                          }
                          type="email"
                          placeholder="Registered Email ID"
                          onBlur={captchaCheck}
                        />
                      </Form.Item>
                      <Form.Item
                        name="userpass"
                        style={{ marginBottom: "1px", marginTop: "-50px" }}
                        rules={requiredRule}
                        hasFeedback
                      >
                        <Input
                          className="formFieldUnderline"
                          prefix={
                            <LockOutlined className="login-signup-icon" />
                          }
                          maxLength={16}
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>

                      <a
                        style={{
                          textAlign: "end",
                          lineHeight: "16px",
                          color: "#AAB2BC",
                        }}
                        onClick={() => setForgotPassword(true)}
                      >
                        Forgot Password?
                      </a>
                    </div>

                    {!captchaStatus && (
                      <Form.Item style={{ marginBottom: "0.5rem" }}>
                        <Button
                          size="large"
                          loading={loader}
                          htmlType="submit"
                          type="secondary"
                          style={{
                            backgroundColor: " rgb(24, 78, 119)",
                            color: "white",
                            // width: "20vw",
                            border: 0,
                            borderRadius: "7px",
                          }}
                        >
                          Sign In
                        </Button>
                      </Form.Item>
                    )}
                  </Form>

                  {captchaStatus && (
                    <Form
                      className="form"
                      form={form3}
                      onFinish={validateCaptcha}
                      size="large"
                      layout="vertical"
                    >
                      <div>
                        <h2 className="blur">
                          <b>
                            <i>{captchaChars}</i>
                          </b>
                        </h2>
                      </div>
                      <Form.Item name="enteredcaptcha" hasFeedback>
                        <Input
                          className="formFieldUnderline"
                          placeholder="Captcha"
                        />
                      </Form.Item>
                      <Form.Item style={{ width: "80%" }}>
                        <Button
                          size="large"
                          loading={loader}
                          htmlType="submit"
                          type="secondary"
                          style={{
                            backgroundColor: "rgb(24, 78, 119)",
                            color: "white",
                            border: 0,
                            borderRadius: "7px",
                            // width: "10vw",
                          }}
                        >
                          Verify
                        </Button>
                        <Button
                          size="large"
                          loading={loader}
                          onClick={createCaptcha}
                          type="secondary"
                          style={{
                            backgroundColor: "rgb(24, 78, 119)",
                            color: "white",
                            marginLeft: "1rem",
                            border: 0,
                            borderRadius: "7px",
                            // width: "5vw",
                          }}
                        >
                          <UndoOutlined />
                        </Button>
                      </Form.Item>
                    </Form>
                  )}
                </div>
              ) : (
                <Signup />
              )}

              <div className="TermsAndCond" style={{ marginBottom: "0.5rem" }}>
                <div className="TermData">
                  By clicking the button above, you agree to our <Space />
                  <a
                    href="https://amotus.online/pages/eula.html"
                    style={{ cursor: "pointer" }}
                    target="_blank"
                  >
                    <span className="termsOfUse">Terms Of Use</span>
                  </a>
                  <Space /> <span>and</span>
                  <a
                    href="https://amotus.online/pages/privacy-policy.html"
                    style={{ cursor: "pointer" }}
                    target="_blank"
                  >
                    <span className="termsOfUse"> Privacy Policy</span>
                  </a>
                </div>
                <a href="https://amotus.online/index.html" target="_blank">
                  <Button
                    size="large"
                    type="secondary"
                    style={{
                      backgroundColor: "rgb(24, 78, 119)",
                      color: "white",
                      marginTop: "1rem",
                      border: 0,
                      borderRadius: "7px",
                      background: "#76C893",
                    }}
                  >
                    Download App
                  </Button>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="loginContainer">
            <ForgotPassword
              forgotpass={forgotPassWord}
              setForgotPassword={setForgotPassword}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
