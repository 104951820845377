import { useState, lazy, useEffect } from "react";
import {
  Card,
  Switch,
  Tooltip,
  notification,
  Radio,
  message,
  Table,
  Tabs,
  Button,
  Modal,
  Input,
  Tag,
  Form,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./styles.less";
import {
  AudioOutlined,
  DeleteColumnOutlined,
  SearchOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import { getPushPathWrapper } from "../../routes";
import Request from "../../request";
import moment from "moment";
import { confirmBox } from "../../utils";
import { putData } from "../../action";
import { Route, Link } from "react-router-dom";
import { replace, set } from "lodash";
import async from "async";
import FilterTable from "../../components/Table";
import FormItem from "../../components/FormItem";
import styles from "./styles.module.css";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 8,
  },
};

const HomeView = (props) => {
  const dispatch = useDispatch();
  const { currentUser, associatedcustomer } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    associatedcustomer: state.global.associatedcustomer,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [check, setCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [param, setParam] = useState();
  const [form] = Form.useForm();

  const deleteUser = (fields) => {
    confirmBox(dispatch, {
      message: "Are you sure you want to Delete",
      onOk: async () => {
        setLoader(true);
        let { user, token, success, message, data } = await Request.deleteUser(
          fields
        );
        setLoader(false);
        if (success) {
          notification.success({
            message: message || "success",
          });
          apiFunction();
        } else {
          notification.error({
            message: message || "Failed",
          });
        }
      },
    });
  };
  const update = (data) => {
    setParam(data?.loginid);
    {
      data?.status == "Y" ? setCheck(true) : setCheck(false);
    }
    setIsModalOpen(true);
    form.setFieldsValue(data);
  };
  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email Id",
      dataIndex: "emailid",
      key: "emailid",
    },
    {
      title: "Created On",
      dataIndex: "createdon",
      key: "Createdon",
      render: (val, r) => {
        return <>{moment.utc(val).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: "Active",
      dataIndex: "status",
      key: "status",
      render: (val, r) => {
        return (
          <Tag
            color={val == "Y" ? "#87d068" : "#f50"}
            style={{
              borderRadius: 30,
              width: 60,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {val == "Y" ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: 180,
      fixed: "right",
      render: (val, record) => {
        return (
          <div className={styles.icons}>
            <div>
              <DeleteOutlined
                style={{ color: "red", fontSize: 20 }}
                onClick={() => deleteUser(record?.loginid)}
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <EditOutlined
                style={{ color: "primary", fontSize: 20 }}
                onClick={() => update(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const apiFunction = async () => {
    setLoader(true);
    let { user, token, success, message, data } = await Request.getUser({
      associatedcustomer: associatedcustomer,
    });
    setLoader(false);
    let newData = [];
    if (success) {
      async.forEach(
        data,
        (val, cb) => {
          if (val.userof != "N") {
            newData.push(val);
          }
          cb();
        },
        () => {
          setDataSource(newData);
        }
      );
    } else {
      setDataSource(() => []);
    }
  };

  useEffect(async () => {
    apiFunction();
  }, []);

  const onFinish = async (valData) => {
    if (param) {
      valData = {
        status: check,
        associatedcustomer: associatedcustomer,
        ...valData,
      };

      setLoader(true);
      let { user, token, success, msg, data } = await Request.updateUser(
        param,
        { ...valData }
      );
      setLoader(false);
      if (success) {
        setIsModalOpen(false);
        apiFunction();
        notification.success({
          message: msg,
        });
      } else {
        notification.error({
          message: msg || "Failed",
        });
      }
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setParam(null);
  };
  const requiredRule = [{ required: true, message: "Please input field" }];

  const onChange = (checked) => {
    setCheck(!check);
  };
  const button = () => {
    dispatch(getPushPathWrapper("addUser"));
  };

  return (
    <div>
      <div className="marginTop" style={{ marginTop: "1rem" }}>
        <div
          className="flex jcsb marginRightLarge"
          style={{
            float: "right",
            margin: "2em 2em",
          }}
        >
          <Button
            type="primary"
            className="buttonPrimaryStyle"
            onClick={button}
            style={{ borderRadius: "50px" }}
          >
            Add Users +
          </Button>
        </div>
        <div style={{ marginInline: "2em" }}>
          <FilterTable
            bordered
            dataSource={dataSource}
            columns={columns}
            // loading={loading}
            scroll={{ x: "auto", y: 600 }}
            pagination={false}
          />
        </div>
      </div>
      <Modal
        title="Update User"
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        // style={{background:"#F2F6F8"}}
      >
        {/* <Form {...layout} form={form} onFinish={onFinish}>
          <FormItem label={"Name"} name="name" rules={requiredRule} />
          <FormItem
            label={"Email Id"}
            name="emailid"
            rules={[
              {
                ...requiredRule[0],
                message: "Input must be of email",
                type: "email",
              },
            ]}
          />
          <div>
            <FormItem
              label={"Active"}
              inputType={"SWITCH"}
              checkedChildren="On"
              defaultValue="status"
              unCheckedChildren="Off"
              checked={check}
              onChange={onChange}
            />
            <FormItem
              inputType="BUTTON"
              tailLayout={tailLayout}
              type="primary"
              buttonStyle={{ width: 180, marginTop: 30 }}
              loading={loader}
              title="SUBMIT"
              htmlType="submit"
            />
          </div>
        </Form> */}

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={"Name"}
            name="name"
            rules={requiredRule}
            className={styles.item}
          >
            <Input
              style={{
                background: "#F2F6F8",
                borderRadius: "10px",
                height: "40px",
              }}
              placeholder="Enter Name"
              className={styles.inputlook}
            />
          </Form.Item>
          <Form.Item
            label={"Email Id"}
            name="emailid"
            rules={[
              {
                ...requiredRule[0],
                message: "Input must be of email",
                type: "email",
              },
            ]}
            className={styles.item}
          >
            <Input
              style={{
                background: "#F2F6F8",
                borderRadius: "10px",
                height: "40px",
              }}
              placeholder="Enter Email"
              className={styles.inputlook}
            />
          </Form.Item>

          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "flex-start",
              marginLeft: "30px",
            }}
          >
            <span style={{ marginRight: "8px" }}>Active</span>
            <FormItem
              inputType="SWITCH"
              checkedChildren="On"
              // name="status"
              unCheckedChildren="Off"
              checked={check}
              onChange={onChange}
            />
          </div>

          {/* <FormItem
            label={'Active'}
            inputType={'SWITCH'}
            checkedChildren="On"
            name="status"
            defaultValue="status"
            unCheckedChildren="Off"
            checked={check}
            onChange={onChange}
          /> */}
          {/* <FormItem
              inputType="BUTTON"
            //   tailLayout={tailLayout}
              type="submit"
              buttonStyle={{ width: 180 }}
            //   loading={loader}
              title="SUBMIT"
              htmlType="submit"
            /> */}

          <Button
            size="large"
            loading={loader}
            htmlType="submit"
            type="secondary"
            style={{
              backgroundColor: " rgb(24, 78, 119)",
              color: "white",
              width: "10vw",
              border: 0,
              borderRadius: "50px",
              marginLeft: "30px",
            }}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default HomeView;
